/*******************************
         Site Overrides
*******************************/
.ui.image {
  &.avatar {
    background-color: @grey;

    &.fixed.bottom {
      position: absolute;
      bottom: 0;
      &.smaller {
        bottom: -(@smallerSize / 2);
      }
    }
    &.smaller {
      height: auto;
      width: @smallerSize;
      &.svg {
        height: @smallerSize;
      }
    }
    &.nospace {
      margin: auto !important;
    }
    &.shadowed {
      box-shadow: @lightBlack 0 2px 4px;
    }
    &.raised {
      z-index: 1;
    }
  }

  &[class*="baseline aligned"] {
    vertical-align: baseline;
  }

  &.iconic {
    &.mini {
      height: @iconicMini;
      width: @iconicMini;
    }
    &.tiny {
      height: @iconicTiny;
      width: @iconicTiny;
    }
    &.small {
      height: @iconicSmall;
      width: @iconicSmall;
    }
    &.medium {
      height: @iconicMedium;
      width: @iconicMedium;
    }
    &.large {
      height: @iconicLarge;
      width: @iconicLarge;
    }
    &.big {
      height: @iconicBig;
      width: @iconicBig;
    }
    &.huge {
      height: @iconicHuge;
      width: @iconicHuge;
    }
    &.massive {
      height: @iconicMassive;
      width: @iconicMassive;
    }
  }

  &.color {
    &.special {
      color: @special;
    }
    &.primary {
      color: @primaryColor;
    }
  }
}
