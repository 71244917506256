/*******************************
         Site Overrides
*******************************/
.app {
  &.-redesignedPage {
    .ui.menu {
      &.-scrolled {
        .secondary.menu {
          .item {
            color: @white;
            &.active {
              color: @white !important;
            }
          }
        }
      }
    }
    .ui.secondary.pointing.menu {
      .item {
        color: @cl-secondary-dark-gray;
        &:hover {
          color: @cl-primary-pinkish-red;
        }
        &.active {
          color: @cl-secondary-dark-gray !important;
          border-color: @cl-primary-pinkish-red;
          &:hover {
            border-color: @cl-primary-pinkish-red;
          }
        }
      }
    }
  }
}

.ui.menu {
  transition: background-color 1s ease;

  &.spaceless.bottom {
    margin-bottom: auto;
  }

  &.transparent {
    background-color: transparent;
  }

  &[class*="center aligned"] .item {
    display: inline-block;
    text-align: center;
  }
}

// Override for header link
header.ui.menu {
  border-radius: 0;
  box-shadow: none;
  font-size: 16px;

  .item {
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  .item.right {
    padding-left: 0;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.ui.secondary.pointing.menu {
  border-width: 0;

  &.center {
    justify-content: center;
  }
}
