/*******************************
         Site Overrides
*******************************/
.ui.header {
  color: #212D3C;

  // Color overrides
  &.special {
    color: @special !important;
  }
  &.white {
    color: @white !important;
  }
  &.primary {
    color: @primaryColor !important;
  }

  &.light {
    font-weight: lighter;
  }

  &.auth {
    font-weight: lighter;
    color: @primaryColor;
    margin-bottom: 0;
    padding-left: @relativeSmall;
    padding-right: @relativeSmall;

    b, strong {
      font-weight: bold;
    }

    @media only screen and (min-width: @tabletBreakpoint) {
      font-size: 2.2rem;
    }
  }

  &.ad {
    font-weight: normal;
    font-size: 2rem;
  }

  &.spaceless:not(.top) {
    margin: auto !important;
  }

  &[class*="spaceless top"] {
    margin-top: auto !important;
  }

  &.alternative {
    border-bottom: 1px solid @darkGrey;
    display: inline-block;
    font-weight: normal;
    line-height: @relativeMassive;
    margin: @relativeBig auto;
    padding: 0 30px;
  }

  &.auth b,
  .underline {
    border-bottom: 2px solid @special;
  }

  small {
    display: block;
    font-weight: 300;
  }
}
