@import "@/styles/scss/_variables.scss";











































.homepage {
  &__steps {
    background: $cl-white;
  }
  &__stepsContent {
    max-width: 1020px;
    margin: 0 auto;
    padding: 30px 15px;
    @media screen and (min-width: $tablet) {
      padding: 40px 0 50px;
    }
  }
}
.homepageSteps {
  &__headline {
    margin-bottom: 20px;

    @media screen and (min-width: $tablet) {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  &__items {
    @media screen and (min-width: $desktop) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: $desktop-small) {
      flex: 0 0 33%;
      align-items: center;
      margin-bottom: 0;
      padding-right: 30px;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__itemIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    margin-right: 15px;
    background-color: $cl-primary;
    border-radius: 50%;
    @media screen and (min-width: $desktop) {
      width: 50px;
      height: 50px;
    }
  }

  &__itemNumber {
    color: $cl-white;
  }

  &__showJobs {
    margin-top: 25px;
    text-align: center;
    @media screen and (min-width: $desktop) {
      margin-top: 30px;
    }
  }
}
