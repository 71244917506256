

































































































































@fontColor: @lightBlack;

.user-menu {
  .mobile-inital {
    display: flex;
    align-items: center;
  }

  &.ui.ui.ui.dropdown > .new.menu {
    border: 0;
    z-index: 100;
    max-width: 100%;
    right: -10px;
    top: 40px;
    width: 300px;
    @media screen and (max-width: @largestMobileScreen) {
      height: 100vh;

      &.visible {
        position: absolute;
        top: -6px;
        right: -15px;
      }

      .mobile-menu {
        width: 300px;
      }
    }
  }
  .navigation-menu {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
