@import "@/styles/scss/_variables.scss";
































.notFound {
  &__content {
    padding: 50px 0;
  }
  &__headline {
    margin-bottom: 10px;
    @media (min-width: $desktop) {
      margin-bottom: 0;
    }
  }
  &__subtitle {
    margin-bottom: 20px;
  }
  &__link {
    display: inline-block;
  }
}
