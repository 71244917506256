









































































































































































.footer-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 70px;
  margin: 0 auto;

  @media only screen and (max-width: @largestMobileScreen) {
    flex-wrap: wrap;
  }

  > .item {
    margin: 2rem;
    width: 50%;
    margin-left: 0;
    margin-right: 0;

    .icon-wrap {
      height: 50px;
    }

    .dropdown .text {
      font-size: 16px;
    }

    @media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
      flex: 0 1 auto;
    }
    @media only screen and (min-width: @computerBreakpoint) {
      flex: 0 1 180px;
    }

    &.menu {
      flex: 0.5 0 auto;
      font-size: 16px;

      @media only screen and (max-width: @largestMobileScreen) {
        flex: 1 0 100%;
        margin: 0 auto 2rem auto;
        order: 2;
      }
    }

    &.right {
      text-align: right;
      @media only screen and (min-width: @computerBreakpoint) {
        flex: 1 1 auto;
      }
    }

    .header {
      font-size: 18px;
      height: 50px;
      margin-bottom: auto;
      font-weight: bold;
    }

    .link {
      display: inline-block;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.5;
      margin-bottom: .8em;
    }

    .ui.stackable.grid {
      margin: auto;

      >.custom {
        padding: 0 !important;
        margin-bottom: 2em !important;

        @media only screen and (min-width: @tabletBreakpoint) {
          &:first-child {
            padding-right: 1rem !important;
          }

          &:last-child {
            padding-left: 1rem !important;
          }
        }
      }
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
