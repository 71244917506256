.ui.menu .item.right {
  flex: 1 1 auto;
  transition: font-size 500ms;
  justify-content: flex-end;
}
@media only screen and (max-width: 767px) {
  .ui.logo {
    width: 100px;
  }
}
.logo-wrapper {
  flex-direction: column;
}
.logo-wrapper .header.item {
  padding: 0;
  height: 100%;
}
.dim {
  display: none;
}
.dim.darkbgr {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}
.item.right {
  justify-content: flex-end;
}
header.ui.menu {
  flex-wrap: wrap;
}
header.ui.menu .item {
  margin-top: 0;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .item.right.newVariant .top-nav {
    padding-bottom: 10px;
  }
}
