@import "@/styles/scss/_variables.scss";


















































.breadcrumbs {
  &__link,
  &__divider {
    display: inline-block;
    color: $cl-black;
  }

  &__link {
    font-size: 12px;
    &:hover {
      color: $cl-tertiary;
    }

    &.-current {
      &:hover {
        color: $cl-black;
      }
    }
  }

  &__divider {
    text-align: center;
    width: 15px;
    margin: 0;
    opacity: .7;
    vertical-align: baseline;
  }
}

.moreJobsView,
.moreJobsOfferView {
  .breadcrumbs__link {
    &:hover {
      color: $cl-primary-lighten-8;
    }
    &.-current {
      &:hover {
        color: $cl-black;
      }
    }
  }
}
