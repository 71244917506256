@import "@/styles/scss/_variables.scss";






















.hero {
  &__wrapper {
    position: relative;
  }

  &__main {
    max-width: 1000px;
    margin: 50px auto 0;
    @media screen and (min-width: $desktop-small) {
      margin-top: 120px;
    }
  }

  &__headline {
    position: relative;
    z-index: 10;
    // color: $cl-mako;
    color: $cl-primary;
  }

  &__title {
    // font: 900 24px/40px $font-primary;
    // @media screen and (min-width: $desktop-small) {
    //   font: 700 32px/40px $font-primary;
    // }
  }

  &__search {
    margin-top: 20px;
  }

  &__citySelector {
    margin: 0 auto;
  }
}
