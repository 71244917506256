.footer-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 70px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .footer-menu {
    flex-wrap: wrap;
  }
}
.footer-menu > .item {
  margin: 2rem;
  width: 50%;
  margin-left: 0;
  margin-right: 0;
}
.footer-menu > .item .icon-wrap {
  height: 50px;
}
.footer-menu > .item .dropdown .text {
  font-size: 16px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu > .item {
    flex: 0 1 auto;
  }
}
@media only screen and (min-width: 992px) {
  .footer-menu > .item {
    flex: 0 1 180px;
  }
}
.footer-menu > .item.menu {
  flex: 0.5 0 auto;
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .footer-menu > .item.menu {
    flex: 1 0 100%;
    margin: 0 auto 2rem auto;
    order: 2;
  }
}
.footer-menu > .item.right {
  text-align: right;
}
@media only screen and (min-width: 992px) {
  .footer-menu > .item.right {
    flex: 1 1 auto;
  }
}
.footer-menu > .item .header {
  font-size: 18px;
  height: 50px;
  margin-bottom: auto;
  font-weight: bold;
}
.footer-menu > .item .link {
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: .8em;
}
.footer-menu > .item .ui.stackable.grid {
  margin: auto;
}
.footer-menu > .item .ui.stackable.grid > .custom {
  padding: 0 !important;
  margin-bottom: 2em !important;
}
@media only screen and (min-width: 768px) {
  .footer-menu > .item .ui.stackable.grid > .custom:first-child {
    padding-right: 1rem !important;
  }
  .footer-menu > .item .ui.stackable.grid > .custom:last-child {
    padding-left: 1rem !important;
  }
}
