@import "@/styles/scss/_variables.scss";


























































.jobOffers {
    &__content {
      position: relative;
      padding: 15px;
      @media (min-width: $desktop-small) {
        max-width: $desktop-layout-width;
        padding: 20px 15px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 20px;
      }
      @media (min-width: $desktop) {
        padding-left: 0;
        padding-right: 0;
      }
      @media (min-width: $desktop-big) {
        max-width: $desktop-big-layout-width;
      }

      &.-empty {
        display: block;
      }
    }
  }
  .jobOffersList {
    &__loader {
      z-index: 10;
      background: rgba($cl-white, .3);
    }
    &__list {
      display: flex;
      flex-direction: column;
      max-width: 410px;

      .moreJobs__banner {
        margin: 15px 0;
      }

      .moreJobsBanner__link {
        margin: 20px 0 8px;
      }
    }
    &__card {
      margin-bottom: 16px;
    }
  }
