@media only screen and (min-width: 767px) {
  .auth-button.top-nav button {
    white-space: nowrap;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
  }
  .auth-button .user-menu {
    transition: all 800ms ease;
  }
}
@media only screen and (min-width: 767px) {
  .headers .auth-button {
    margin-left: 30px;
  }
}
