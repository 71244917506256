@import "@/styles/scss/_variables.scss";









































.moreJobsView,
.moreJobsOfferView {
  &__heroSearchResult {
    margin-right: 7px;
    color: $cl-primary-darken-12;
  }

  .hero {
    &__backLink {
      position: relative;
      color: $cl-primary-lighten-8;

      @media (min-width: $desktop-small) {
        display: none;
      }
    }

    &__breadcrumbs {
      position: relative;
      display: none;

      @media (min-width: $desktop-small) {
        display: block;
        min-height: 16px;
      }
    }

    &__headline {
      margin-top: 20px;
    }

    &__content {
      position: relative;
      padding: 65px 15px 20px;

      @media (min-width: $desktop-small) {
        padding: 100px 16px 32px;
      }
      @media (min-width: $desktop-small) {
        max-width: $desktop-layout-width;
        margin: 0 auto;
      }
      @media (min-width: $desktop) {
        padding-left: 0;
        padding-right: 0;
      }
      @media (min-width: $desktop-big) {
        max-width: $desktop-big-layout-width;
      }
    }
  }
}
