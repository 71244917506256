








































































































































footer.ui.segment {
  padding-bottom: 0;
  padding-top: 0;
}

.footerLP.ten.wide.stackable {
  padding-bottom: 30px;
  padding-top: 30px;
  margin-bottom: 0;
}

.social {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 1.4em;

  .column {
    &.divider {
      flex: 1 0 100%;
      @media all and (min-width: @computerBreakpoint) {
        flex: 0 1 0px;
      }
    }
    .icon {
      margin: 0 0 10px 10px;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
