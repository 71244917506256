@import "@/styles/scss/_variables.scss";
































.redirectPage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: $cl-white;

  &__info {
    width: 400px;
    max-width: 90%;
    color: $cl-white;
    margin-top: 20px;
  }

  &__headline {
    margin-bottom: 15px;
    color: $cl-primary;
    &.aj-text {
      font-size: 20px;
      line-height: 30px;

      @media (min-width: $tablet) {
        font-size: 32px;
        line-height: 48px;
      }
    }
  }
}
