


























































.city-selector {
  position: relative;
  width: 100%;

  & /deep/ .aj-loader {
    border-radius: 24px;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
