@import "@/styles/scss/_variables.scss";



























































.city-selector {
  .ui.dropdown {
    border: none;
    padding: 0;
    border-radius: 16px;

    @media (max-width: $mobile) {
      padding: 10px 10px 10px 15px;
      min-height: 30px;
    }

    .default.text {
      color: $cl-mako-lighten-32;
    }

    &.selection {
      padding: 0;
      border-radius: 16px;

      .text {
        top: -35px;
        left: 55px;
        font-size: 16px;
        color: $cl-mako-lighten-32;
      }
    }
  }
}

.worktownStep {
  .ui.dropdown.selection>.text {
    left: 14px;
    top: -32px;
    font-size: 14px;
    color: $cl-mako-lighten-32;
  }
}

.moreJobsView__searchLocation {
  .city-selector .ui.dropdown.selection .text {
    left: 20px;
    color: $cl-mako;
  }
}

#city-selector {
  width: 100%;

  :focus {
    border: 1px solid transparent;
  }
}
