/*******************************
         Site Overrides
*******************************/

@largeMobileWidth: 425px;

.ui.segment {
  &[class*="not separated"] {
    border-bottom: none;
  }

  &.vertical.masthead {
    border-bottom: none;
    padding-top: 62px;

    &.effect
    &.city {
      background-size: cover;
      background-position: center center;
    }

    &.effect {
      background-blend-mode: multiply;
    }
  }
  &.vertical.stripe {
    font-size: @relativeMedium;
    padding-bottom: (@relativeMassive * 3);
    padding-top: (@relativeMassive * 3);

    @media only screen and (min-width: @tabletBreakpoint) {
      font-size: @relativeLarge;
    }

    &.small {
      padding-bottom: @relativeMassive;
      padding-top: @relativeMassive;
    }
  }
  &.vertical.messages {
    padding-top: 80px;
  }
  &.vertical {
    &.only.top[class*="very padded"] {
      padding: 0;
      padding-top: @veryPaddedSegmentPadding;
    }
    &.only.top[class*="medium padded"] {
      padding: 0;
      padding-top: @paddedSegmentPadding;
    }
    &[class*="top stripe"] {
      padding-bottom: 0;
    }
    &.inverted.footer {
      background-color: @white;
    }
    &.underlined {
      border-bottom: 1px solid @grey;
    }
  }

  @media only screen and (max-width: @largestMobileScreen) {
    &.mini-mobile {
      font-size: 0.6em;
    }
  }

  &.has-overlays {
    margin-top: -1px;
    padding-top: 1px;
    position: relative;
  }
  &.full.screen {
    height: 90vh;
  }

  &.white {
    background-color: @white;
  }

  &.gradient {
    background: @primaryColor;
    background-image: linear-gradient(134deg, @primaryColor 0%, #050720 100%);
  }
  &.dark {
    background-color: @grey;
  }
}

.use-dark + .ui.segment.vertical.footer,
.dark + .ui.segment.vertical.footer {
  background-color: @grey;
}
