@import "@/styles/scss/_variables.scss";
















































































.homepage__about {
  padding: 30px 15px;
  background-color: $cl-light-shades;
  color: $cl-secondary;

  @media (min-width: $desktop) {
    padding: 40px 0 50px;
  }
}
.homepageAbout {
  &__headline {
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: $tablet) {
      text-align: left;
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  &__content {
    max-width: 1280px;
    margin: 0 auto;
  }

  &__description {
    @media screen and (min-width: $desktop) {
      max-width: 800px;
      margin: 0 auto;
    }
  }
  &__itemRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: $desktop) {
      flex-direction: row;
      margin-bottom: 0;
    }
  }
  &__item {
    @media screen and (min-width: $desktop) {
      flex: 1 1 auto;
      width: 50%;
    }

    &.-text {
      @media screen and (min-width: $desktop) {
        padding: 25px;
      }
    }

    &.-image {
      display: none;
      @media screen and (min-width: $desktop) {
        display: block;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      width: 100%;
    }
  }

  &__showJobs {
    margin-top: 20px;

    &.-mobile {
      @media screen and (min-width: $desktop) {
        display: none;
      }
    }
    &.-desktop {
      display: none;
      @media screen and (min-width: $desktop) {
        display: block;
      }
    }
  }
}
