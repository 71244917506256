























.auth-button {
  @media only screen and (min-width: @largestMobileScreen) {
    &.top-nav button {
      white-space: nowrap;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
    }

    .user-menu {
      transition: all 800ms ease;
    }
  }
}
.headers {
  .auth-button {
    @media only screen and (min-width: @largestMobileScreen) {
      margin-left: 30px;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
