@import "@/styles/scss/_variables.scss";















.showJobsButton {
  display: inline-block;
}
