

// importing variables default values from @appjobs/ui library
@import '@appjobs/ui/src/styles/_variables.scss';

// override variables for application here, example:
// $cl-radical-red: red;

// OVERRIDE APPJOBS-UI VARIABLES
////////// COLORS

// main brand color
$cl-primary: #1A7FE8;
$cl-primary-darken-12: darken($cl-primary, 12%);
$cl-primary-lighten-8: lighten($cl-primary, 8%);
$cl-primary-lighten-16: lighten($cl-primary, 16%);
$cl-primary-lighten-24: lighten($cl-primary, 24%);
$cl-primary-lighten-32: lighten($cl-primary, 32%);

// shades
$cl-secondary: #212D3C;
$cl-secondary-darken-12: darken($cl-secondary, 12%);
$cl-secondary-lighten-4: lighten($cl-secondary, 4%);
$cl-secondary-lighten-8: lighten($cl-secondary, 8%);
$cl-secondary-lighten-12: lighten($cl-secondary, 12%);
$cl-secondary-lighten-16: lighten($cl-secondary, 16%);

$cl-light-shades: #F5F9FF;

////////// APP VARIABLES
$cl-greyF9: #f9fafb;

$desktop-layout-width: 1125px;
$desktop-big-layout-width: 1280px;

////////// COMPONENTS VARIABLES

// AjButton
$ajButton-default-backgroundColor: $cl-secondary;
$ajButton-default-borderRadius: 16px;
$ajButton-default-disabled-backgroundColor: $cl-mako-lighten-48;
$ajButton-default-transition: none;

// AjInput
$ajInput-special-ajInputTextField-border-radius: 16px;
$ajInput-special-ajInputTextField-border: 1px solid $cl-mako-lighten-48;
$ajInput-special-ajInputTextField-hover-border: 1px solid $cl-mako-lighten-48;
$ajInput-special-ajInputTextField-boxShadow: none;
$ajInput-special-ajInputTextField-hover-boxShadow: none;

// AjSelect
$ajSelect-options-borderBottomLeftRadius: 16px;
$ajSelect-options-borderBottomRightRadius: 16px;
