.user-menu .mobile-inital {
  display: flex;
  align-items: center;
}
.user-menu.ui.ui.ui.dropdown > .new.menu {
  border: 0;
  z-index: 100;
  max-width: 100%;
  right: -10px;
  top: 40px;
  width: 300px;
}
@media screen and (max-width: 767px) {
  .user-menu.ui.ui.ui.dropdown > .new.menu {
    height: 100vh;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu.visible {
    position: absolute;
    top: -6px;
    right: -15px;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu {
    width: 300px;
  }
}
.user-menu .navigation-menu {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
