@import "@/styles/scss/_variables.scss";











.homepage {
  &__wrapper {
    &.-hero {
      // background-color: $cl-dawn-pink-lighten-8;
      // overflow: hidden;

      .homepage__content {
        @media screen and (min-width: $desktop-small) {
          padding-bottom: 60px;
        }
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 30px 15px;
    @media screen and (min-width: $desktop-small) {
      padding-left: 64px;
      padding-right: 64px;
    }
  }
}
