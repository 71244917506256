@import "@/styles/scss/_variables.scss";







.hero {
  position: relative;
}
