@import "@/styles/scss/_variables.scss";






















.cookieNag {
  position: fixed;
  bottom: 0;
  z-index: 5005;
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 11px 16px;
  background-color: lighten($cl-black, 20%);
  opacity: .95;
  @media (min-width: $mobile-big) {
    justify-content: center;
  }

  &.-visible {
    display: flex;
  }

  &__text {
    margin-right: 20px;
    color: $cl-white
  }
}
